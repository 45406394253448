import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { IFnbHospitalityItem } from ".";
import { ApiDeleteFile, ApiService, ApiShowError, ApiUploadFiles, areManyDateTimeRangesOverlapping, changeDataModify, formatOptions, getValueId, removeTime, removeTimeInt, sortWithDate } from "../../../../theme/helpers";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { ExtendClearValue, FormSubmit, InputCheck, InputDate, InputMutiImage, InputPrice, InputSelect, InputSingleImage, KTFormItem, KTSVG, RadioBoolean, TextFormByUser, yup } from "../../../../theme/partials";
import { InputCheckBox } from "../../../../theme/partials/widgets/form/InputCheckbox";
import { apiPrivate, IInitField } from "../../../modules/api";
import { apiFnb, fetchQuery } from "../../../modules/api/fnb";
import { popupMessage } from "../../../modules/messages";
import { MergedProps } from "../../../router/AppRoutes";
import { useQuery } from "react-query";
import SearchPopup from "../../../../theme/partials/layout/search-popup";

const defaultValues: IFNBHospitalityItemsForm = {
    itemid: null,
    code: null,
    name: null,
    description: null,
    stock: null,
    active: false,
    barcodeno: null,
    unitofmeasure: null,
    tax: null,
    tag: undefined,
    categorycode: null,
    subcategorycode: null,
    hasallergen: false,
    hassideitem: false,
    hascombo: false,
    hasvariants: false,
    isrecommended: false,
    isfeatured: false,
    maxinventory: null,
    maxdishperday: null,
    customizationid: null,
    HospItemVariants: [],
    HospItemPrices: [],
    vatprodpostinggroup: null,
    productionsections: []
}

export interface IHospItemVariant extends IInitField {
    hospitemid: string | null
    hospitemcode: string | null
    hospitemname: string | null
    code: string | null
    name: string | null
    image?: any
    calories: number | null
    stock: boolean
}

export interface IHospitemPrice extends IInitField {
    customerpricegroupcode?: string
    hospitemid: string | null
    hospitemcode: string | null
    hospitemname: string | null
    hospitemvariantid: string | null
    hospitemvariantcode: string | null
    hospitemvariantname: string | null
    unitofmeasure: string | null
    unitprice: number | null
    inclvat: boolean
    starteddate?: any
    endeddate?: any
}

export interface IFNBHospitalityItemsForm extends IFnbHospitalityItem {
    HospItemVariants: IHospItemVariant[]
    HospItemPrices: IHospitemPrice[]
}

const tablist = [
    { title: 'General', ico: '/media/icons/tabs/info (1).svg' },
    { title: 'Variants', ico: '/media/icons/tabs/product-_1_.svg' },
    { title: 'Prices', ico: '/media/icons/tabs/money.svg' },
]

export const optionVAT = [
    { value: 'NOVAT', label: 'NOVAT Hàng hóa, dịch vụ mua vào không chịu thuế' },
    { value: 'VAT00', label: 'VAT00 Hàng hóa, dịch vụ mua vào chịu thuế suất 0%' },
    { value: 'VAT05', label: 'VAT05 Hàng hóa, dịch vụ mua vào chịu thuế suất 5%' },
    { value: 'VAT08', label: 'VAT08 Hàng hóa, dịch vụ mua vào chịu thuế suất 08%' },
    { value: 'VAT10', label: 'VAT10 Hàng hóa, dịch vụ mua vào chịu thuế suất 10%' },
    { value: 'VAT10_F', label: 'VAT10_F Hàng hóa, dịch vụ mua vào chịu thuế suất 10%' },
    { value: 'VAT5_F', label: 'VAT5_F Hàng hóa, dịch vụ mua vào chịu thuế suất 5%' },
    { value: 'VAT8_F', label: 'VAT8_F Hàng hóa, dịch vụ mua vào chịu thuế suất 8%' },
    { value: 'VATKKK', label: 'VATKKK Hàng hóa, dịch vụ mua vào không cần kê khai' },
]

export function FNBHospitalityItemsForm({ keyElement, pathElement, permission }: MergedProps) {
    const urlApi = 'fnb/hospitems'
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, isEditWithPermisstion, valueid } = getValueId(id, permission)
    const { data, isSuccess, isFetching, refetch } = apiFnb.useHospitalityItemsId<IFNBHospitalityItemsForm>(valueid)
    const { register, control, reset, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IFNBHospitalityItemsForm>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            name: yup.string().required(),
            maxinventory: yup.number().nullable(true).transform(val => val ? Number(val) : null),
            maxdishperday: yup.number().nullable(true).transform(val => val ? Number(val) : null),
            productionsections: yup.array().of(yup.string()).default([]),
            HospItemVariants: yup
                .array()
                .of(yup.object().shape({
                    code: yup.string().test('unique', (val, ctx) => {
                        const arr = watch("HospItemVariants")?.filter(f => f.code == val)
                        if (arr.length > 1) return ctx.createError()
                        return true
                    }).required(),
                    name: yup.string().required(),
                    calories: yup.number().nullable(true).transform(val => val ? Number(val) : null)
                })),
            HospItemPrices: yup
                .array()
                .of(yup.object().shape({
                    hospitemvariantid: yup.string().nullable(true).transform(val => val || null).test('unique_uom', (val, ctx) => {
                        const { customerpricegroupcode, unitofmeasure, starteddate, endeddate } = ctx.parent as any
                        // get prices with duplicate hospitemvariantid && unitofmeasure && customerpricegroupcode
                        const prices = (watch("HospItemPrices") ?? [])
                            .filter(f => f.hospitemvariantid == val && f.unitofmeasure == unitofmeasure && f.customerpricegroupcode == customerpricegroupcode)
                        if (prices.length > 1) {
                            return ctx.createError()
                        }
                        return true
                    }),
                    unitprice: yup.number().required(),
                    starteddate: yup
                        .string()
                        .test('require_with_duplicate_start', (val, ctx) => {
                            if (!val) {
                                return ctx.createError()
                            }
                            const { hospitemvariantid, unitofmeasure, starteddate, endeddate } = ctx.parent as any

                            // check duplicate 1.hospitemvariantid 2.unitofmeasure
                            // filter !!start !!end
                            let prices = (watch('HospItemPrices') ?? [])
                                .filter(f => f.hospitemvariantid == hospitemvariantid && f.unitofmeasure == unitofmeasure)
                                .filter(f => f.starteddate && f.endeddate)
                            // return if duplicate start && end (length = 1 ok)
                            if (prices.filter(f => f.starteddate == starteddate && f.endeddate == endeddate).length > 1) {
                                return ctx.createError()
                            }
                            if (starteddate && endeddate) {
                                const start = removeTime(starteddate)
                                const end = removeTime(endeddate)
                                if (start == end) {
                                    return ctx.createError()
                                }
                                // filter duplicate start && end
                                prices = prices.filter(f => f.starteddate != ctx.parent['starteddate'] && f.endeddate != ctx.parent['endeddate'])
                                // check overlap time
                                const { isChecked, field } = areManyDateTimeRangesOverlapping(
                                    { start, end },
                                    prices.map(m => ({ start: removeTime(m.starteddate), end: removeTime(m.endeddate) }))
                                )
                                if (isChecked && field == 'start') {
                                    return ctx.createError()
                                }
                            }
                            return true
                        }),
                    endeddate: yup
                        .string()
                        .test('require_with_duplicate_end', (val, ctx) => {
                            const { hospitemvariantid, unitofmeasure, starteddate, endeddate } = ctx.parent as any
                            let prices = (watch('HospItemPrices') ?? [])
                                .filter(f => f.hospitemvariantid == hospitemvariantid && f.unitofmeasure == unitofmeasure)
                            // 
                            if (!val && hospitemvariantid && unitofmeasure && prices.length > 1) {
                                return ctx.createError()
                            }
                            // filter duplicate start && end
                            if (starteddate && endeddate) {
                                const start = removeTime(starteddate)
                                const end = removeTime(endeddate)
                                if (start == end) {
                                    return ctx.createError()
                                }
                            }
                            return true
                        })
                        .nullable(true)
                        .transform(val => val ? val : null),
                }))
                .nullable(true),
        })),
    })
    const [tabListId, setTabListId] = useState('General')

    useEffect(() => {
        if (isSuccess && !isFetching)
            reset({ ...data, productionsections: data.productionsections ?? [] })
    }, [isFetching])

    const { data: dataItems, isSuccess: isItems, isLoading } = apiPrivate.useItems()
    const [optionItems, setoptionItems] = useState<any[]>([])
    useEffect(() => {
        if (isItems && !isLoading) {
            setoptionItems(formatOptions(dataItems, ['itemno', 'itemno', 'description']))
        }
    }, [isLoading])

    const { data: dataTags, isSuccess: isTags } = apiFnb.useTags()
    const optionTags = isTags ? formatOptions(dataTags, ['id', 'name']) : []

    const { data: dataUoms, isSuccess: isUoms } = apiPrivate.useUoms()
    const optionUoms = isUoms ? formatOptions(dataUoms, ['code', 'displayname']) : []

    const { data: dataCategories, isSuccess: isCategories } = apiFnb.useCategories()
    const optionCategories = isCategories ? formatOptions(dataCategories, ['code', 'name']) : []

    const { data: dataSubCategories, isSuccess: isSubCategories } = apiFnb.useSubCategories()
    const optioSubCategories = isSubCategories ? formatOptions(dataSubCategories, ['code', 'name']) : []

    const { data: dataCustomizations, isSuccess: isCustomizations } = apiFnb.useCustomizations()
    const optionCustomizations = isCustomizations ? formatOptions(dataCustomizations, ['id', 'name']) : []

    const optionHospItemVariants = formatOptions(watch('HospItemVariants') || [], ['id', 'code']) || []

    async function handleDeleteLine(index: number, key: any) {
        if (!watch(key)) return popupMessage({ icon: "error" })
        return setValue(key, watch(key).filter((f: any, i: number) => i != index) || [])
    }

    async function changeUpload(tableName: string, key: string, formData: any) {
        const uuid = formData['id']
        let resultsFile = formData[key]
        const isUpload = Array.isArray(formData[key]) && formData[key].filter((f: any) => f.state != 'load')?.length

        if (isUpload && uuid) {
            const oldFile: any[] = formData[key].filter((f: any) => f.state == "load")
            const removeFile: any[] = formData[key].filter((f: any) => f.state == "remove")
            const uploadFile: any[] = formData[key].filter((f: any) => f.state == 'upload')

            resultsFile = [...oldFile]
            if (uploadFile.length) {
                const resultsUpload = await ApiUploadFiles(uuid, tableName, uploadFile)
                if (resultsUpload && resultsUpload.length) resultsUpload.forEach((up: any) => resultsFile.push({ ...up, state: 'load', }))
            }
            if (removeFile.length) {
                removeFile.forEach(async file => await ApiDeleteFile(file.pathtofile))
            }
        }
        const checkedFile = formData[key]?.find((f: any) => f.isDefault && f.state != "remove")
        if (checkedFile) {
            resultsFile = resultsFile.map((item: any) => item.fileName == checkedFile.fileName ? ({ ...item, isDefault: true }) : item)
        }
        return resultsFile
    }

    function getErrorTab(key: string) {
        return (Array.isArray(errors['HospItemPrices']) && errors['HospItemPrices']?.length && key == 'Prices')
            || (Array.isArray(errors['HospItemVariants']) && errors['HospItemVariants']?.length && key == 'Variants')
            || ([errors.name, errors.maxinventory, errors.maxdishperday].filter(f => f).length && key == 'General')
    }

    const onSubmit = async (data: IFNBHospitalityItemsForm) => {
        try {
            if (isEdit) {
                data.images = await changeUpload('hospitems', 'images', data)
            }
            data = await (await ApiService.post(`${urlApi}/upsertwithdetails`, changeDataModify(data))).data
            if (!isEdit) {
                data.images = await changeUpload('hospitems', 'images', data)
            }
            popupMessage({ icon: 'success', autoClose: true })
            navigate(pathElement)
        } catch (error) { ApiShowError(error) }
    }

    const refSubmit = useRef<HTMLButtonElement | null>(null)

    const [cellIndex, setCellIndex] = useState<{ row: number, col: number, value: any, id: any } | undefined>()

    const { data: customerPriceGroups } = useQuery(['fnb/customerpricegroups'], () => fetchQuery('customerpricegroups'))
    const optionAssignTos: any[] = (customerPriceGroups ?? []).map((m: any) => ({ ...m, title: m.code, description: m.description }))

    return <>
        <ContentHeader isInfo title={keyElement} items={[{ title: keyElement, path: pathElement }]} elements={<>
            <TextFormByUser data={watch()} />
            <FormSubmit
                type={isEdit}
                isSubmitting={isSubmitting}
                permission={permission}
                handleSubmit={() => refSubmit.current?.click()}
                handleClose={() => navigate(pathElement)} />
        </>} />
        <form
            className="card"
            onSubmit={handleSubmit(onSubmit, error => console.log(error))}>
            <button ref={refSubmit} type="submit" className="d-none"></button>
            <div className="card-header bg-secondary rounded-top">
                <h3 className="card-title gap-1">
                    {tablist.map(text => <div key={text.title} onClick={() => setTabListId(text.title)} className={clsx('btn p-3 py-1 pt-2 rounded min-w-125px text-center', {
                        'bg-orange text-white': tabListId == text.title,
                        'bg-danger': tabListId == text.title && getErrorTab(text.title),
                        'border border-danger border-dashed': tabListId != text.title && getErrorTab(text.title)
                    })}>
                        <KTSVG path={text.ico} className={clsx(`svg-icon-2 d-block m-0`, tabListId == text.title ? 'svg-icon-white' : 'svg-icon-dark')} />
                        <FormattedMessage id={`text.${text.title.toLowerCase()}` as any} />
                    </div>)}
                </h3>
            </div>
            <div className="card-body">
                <div className={clsx("row", { 'd-none': tabListId != 'General' })}>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.item'>
                            <Controller
                                control={control}
                                name='code'
                                render={({ field: { value, onChange } }) => <InputSelect
                                    isAsync
                                    isLoading={isLoading}
                                    options={optionItems}
                                    value={optionItems.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                        setValue("itemid", e.id)
                                        setValue('unitofmeasure', e.baseunitofmeasure)
                                        setValue('name', e.description)
                                        setValue("vatprodpostinggroup", e.vatprodpostinggroup)
                                    }}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.name'>
                            <input className={`form-control form-control-sm ${errors.name && 'form-error'}`} {...register('name')} />
                        </KTFormItem>
                        <KTFormItem title='text.description' isHeight>
                            <textarea className={clsx(`form-control form-control-sm`, { 'form-error': errors.description })} {...register('description')} rows={4} />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.images' isBLockLabel>
                            <Controller
                                control={control}
                                name='images'
                                render={({ field: { value, onChange } }) => <InputMutiImage
                                    isChecked
                                    value={value}
                                    onChange={(e) => {
                                        if (!e) return onChange(null)
                                        onChange(e)
                                    }}
                                />}
                            />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.category'>
                            <Controller
                                control={control}
                                name='categorycode'
                                render={({ field: { value, onChange } }) => <InputSelect
                                    options={optionCategories}
                                    value={optionCategories.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        setValue("subcategorycode", null)
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                    }}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.tag'>
                            <Controller
                                control={control}
                                name='tag'
                                render={({ field: { value, onChange } }) => <InputSelect
                                    isMulti
                                    options={optionTags}
                                    value={optionTags.filter(f => value?.map((i: any) => i.id).includes(f.value)) || null}
                                    onChange={(e) => {
                                        if (!e) return onChange(null)
                                        onChange(e.map((v: any) => ({ id: v.id, code: v.code, name: v.name })))
                                    }}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.unit-of-measure'>
                            <Controller
                                control={control}
                                name='unitofmeasure'
                                render={({ field: { value, onChange } }) => <InputSelect
                                    options={optionUoms}
                                    value={optionUoms.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                    }}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='VAT'>
                            <Controller
                                control={control}
                                name='vatprodpostinggroup'
                                render={({ field: { value, onChange } }) => <InputSelect
                                    options={optionVAT}
                                    value={optionVAT.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                    }}
                                />}
                            />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.sub-category'>
                            <Controller
                                control={control}
                                name='subcategorycode'
                                render={({ field: { value, onChange } }) => <InputSelect
                                    options={optioSubCategories.filter(f => f.categorycode == watch("categorycode"))}
                                    value={optioSubCategories.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                    }}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.item-has-allergen'>
                            <div className="d-flex">
                                <RadioBoolean control={control} name="hasallergen" className="form-check form-check-sm form-check-custom me-3 w-100px" />
                            </div>
                        </KTFormItem>
                        <KTFormItem title='text.side-item'>
                            <div className="d-flex">
                                <RadioBoolean control={control} name="hassideitem" className="form-check form-check-sm form-check-custom me-3 w-100px" />
                            </div>
                        </KTFormItem>
                        <KTFormItem title='Production sections'>
                            <Controller
                                control={control}
                                name='productionsections'
                                render={({ field: { value, onChange } }) => <InputSelect
                                    isMulti
                                    options={[{ value: 'KITCHEN', label: 'KITCHEN' }, { value: 'BAR', label: 'BAR' }]}
                                    value={[{ value: 'KITCHEN', label: 'KITCHEN' }, { value: 'BAR', label: 'BAR' }].filter(f => value?.includes(f.value))}
                                    onChange={(option) => {
                                        if (!option) return onChange([])
                                        onChange(option.map((f: any) => f.value))
                                    }}
                                    className={clsx({ 'form-error': errors.productionsections })}
                                />}
                            />
                        </KTFormItem>
                    </div>
                    <div className="col-12">
                        <hr />
                        <div className="card-header px-0 pt-0 border-bottom-0">
                            <div className="card-title"><FormattedMessage id="text.settings" /></div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                <KTFormItem title='text.stock' isBLockLabel>
                                    <div className="d-flex">
                                        <RadioBoolean control={control} name="stock" labels={['stock-in', 'stock-out']} className="form-check form-check-sm form-check-custom me-3 w-100px" />
                                    </div>
                                </KTFormItem>
                                <KTFormItem title='text.has-sku-image' isBLockLabel>
                                    <div className="d-flex">
                                        <RadioBoolean control={control} name="hasvariants" className="form-check form-check-sm form-check-custom me-3 w-100px" />
                                    </div>
                                </KTFormItem>
                                <KTFormItem title='text.customization' isBLockLabel>
                                    <Controller
                                        name="customizationid"
                                        control={control}
                                        render={({ field: { value, onChange } }) => <InputSelect
                                            isClearable
                                            options={optionCustomizations}
                                            value={optionCustomizations.find(f => f.value == value) || null}
                                            onChange={(e) => {
                                                if (!e) return onChange(null)
                                                onChange(e.value)
                                            }}
                                        />}
                                    />
                                </KTFormItem>
                            </div>
                            <div className="col-12 col-lg-4">
                                <KTFormItem title='text.active' isBLockLabel>
                                    <div className="d-flex">
                                        <RadioBoolean control={control} name="active" className="form-check form-check-sm form-check-custom me-3 w-100px" />
                                    </div>
                                </KTFormItem>
                                <KTFormItem title='text.is-combo' isBLockLabel>
                                    <div className="d-flex">
                                        <RadioBoolean control={control} name="hascombo" className="form-check form-check-sm form-check-custom me-3 w-100px" />
                                    </div>
                                </KTFormItem>
                                <KTFormItem title='text.max-dish-per-day' isBLockLabel>
                                    <input className={`form-control form-control-sm ${errors.maxdishperday && 'form-error'}`} {...register('maxdishperday')} />
                                </KTFormItem>
                            </div>
                            <div className="col-12 col-lg-4">
                                <KTFormItem title='text.max-inventory' isBLockLabel>
                                    <input className={`form-control form-control-sm ${errors.maxinventory && 'form-error'}`} {...register('maxinventory')} />
                                </KTFormItem>
                                <KTFormItem title='text.recommendation' isBLockLabel>
                                    <div className="d-flex gap-6">
                                        <InputCheckBox {...register('isrecommended')} type='checkbox'><FormattedMessage id="text.recommended" /></InputCheckBox>
                                        <InputCheckBox {...register('isfeatured')} type='checkbox'><FormattedMessage id="text.featured" /></InputCheckBox>
                                    </div>
                                </KTFormItem>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={clsx("d-flex flex-column", { 'd-none': tabListId != 'Variants' })}>
                    {watch('HospItemVariants').map((line, index) => <div key={index} className={clsx("card border border-secondary mb-4 d-row-hover", /* { 'd-none': line.state == 'delete' } */)}>
                        <div className="card-body pb-0">
                            <div className="row">
                                <div className="col-12 col-lg-5">
                                    <KTFormItem title='text.hospitality-item.variant.id'>
                                        <input
                                            {...register(`HospItemVariants.${index}.code`, {
                                                onChange(event) {
                                                    return String(event.target.value).toUpperCase()
                                                },
                                            })}
                                            className={clsx(`form-control form-control-sm`, {
                                                'form-error': errors.HospItemVariants?.length && errors.HospItemVariants[index]?.code
                                            })}
                                        />
                                    </KTFormItem>
                                    <KTFormItem title='text.how-many-calories'>
                                        <input
                                            type={'number'}
                                            {...register(`HospItemVariants.${index}.calories`)}
                                            className={clsx(`form-control form-control-sm`, {
                                                'form-error': errors.HospItemVariants?.length && errors.HospItemVariants[index]?.calories
                                            })}
                                        />
                                    </KTFormItem>
                                    <KTFormItem title='text.stock'>
                                        <RadioBoolean control={control} name={`HospItemVariants.${index}.stock`} className="form-check form-check-sm form-check-custom w-80px" />
                                    </KTFormItem>
                                </div>
                                <div className="col-12 col-lg-7">
                                    <KTFormItem title='text.name'>
                                        <input
                                            {...register(`HospItemVariants.${index}.name`)}
                                            className={clsx(`form-control form-control-sm`, {
                                                'form-error': errors.HospItemVariants?.length && errors.HospItemVariants[index]?.name
                                            })}
                                        />
                                    </KTFormItem>
                                    <KTFormItem title='text.image' isBLockLabel>
                                        <Controller
                                            name={`HospItemVariants.${index}.image`}
                                            control={control}
                                            render={({ field: { value, onChange } }) => <InputSingleImage
                                                value={value}
                                                onChange={onChange}
                                            />}
                                        />
                                    </KTFormItem>
                                </div>
                            </div>
                        </div>
                        <div className={clsx("d-flex w-100", { 'd-none': isEdit && !isEditWithPermisstion })}>
                            <span className="btn btn-icon btn-sm btn-light-danger ms-auto d-cell-hover" onClick={() => handleDeleteLine(index, 'HospItemVariants')}>
                                <KTSVG path={'/media/icons/duotune/general/gen027.svg'} className={`svg-icon-4 svg-icon-danger`} />
                            </span>
                        </div>
                    </div>)}
                    <div className={clsx("card card-body border border-secondary", { 'd-none': isEdit && !isEditWithPermisstion })}>
                        <span
                            className={clsx("btn btn-icon btn-sm btn-primary rounded-circle mx-auto")}
                            onClick={() => setValue('HospItemVariants', [...(watch('HospItemVariants') || []), {
                                hospitemid: watch('id') as string,
                                hospitemcode: watch('code'),
                                hospitemname: watch('name'),
                                code: null,
                                name: null,
                                image: null,
                                calories: null,
                                stock: true
                            }])}>
                            <i className="bi bi-plus-lg fs-1"></i>
                        </span>
                    </div>
                </div>
                <div className={clsx("d-flex flex-column", { 'd-none': tabListId != 'Prices' })}>
                    {watch("HospItemPrices").map((line, index) => <div key={index} className={clsx("card border border-secondary mb-4 d-row-hover",)}>
                        <div className="card-body pb-0">
                            <table className="table m-0 gx-4">
                                <thead>
                                    <tr>
                                        <th className="py-0 w-150px">Assign to</th>
                                        <th className="py-0 w-125px"><FormattedMessage id="text.hospitality-item.variant.id" /></th>
                                        <th className="py-0 w-125px"><FormattedMessage id="text.unit-of-measure" /></th>
                                        <th className="py-0"><FormattedMessage id="text.unit-price" /></th>
                                        <th className="py-0 w-80px"><FormattedMessage id="text.incl-vat" /></th>
                                        <th className="py-0"><FormattedMessage id="text.starting-date" /></th>
                                        <th className="py-0"><FormattedMessage id="text.ending-date" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="position-relative">
                                                <Controller
                                                    control={control}
                                                    name={`HospItemPrices.${index}.customerpricegroupcode`}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input className="form-control form-control-sm" value={value} onChange={(event) => onChange(event.target.value)} readOnly />
                                                    )}
                                                />
                                                <div className="position-absolute top-0 end-0 h-100 d-flex flex-center cell-hover">
                                                    <button
                                                        className="btn p-0 h-20px w-20px me-2 bg-gray-100 border my-auto"
                                                        onClick={(event) => {
                                                            event.preventDefault()
                                                            event.stopPropagation()
                                                            setCellIndex({ row: index, col: 0, value: watch(`HospItemPrices.${index}.customerpricegroupcode`), id: watch(`HospItemPrices.${index}.id`) })
                                                        }}
                                                    >
                                                        <i className="bi bi-search p-1 text-primary"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`HospItemPrices.${index}.hospitemvariantid`}
                                                render={({ field: { value, onChange } }) => <InputSelect
                                                    isClearable
                                                    className={clsx('min-w-150px', { "form-error": errors.HospItemPrices && errors.HospItemPrices[index]?.hospitemvariantid })}
                                                    options={optionHospItemVariants
                                                        // .filter(f => !watch('HospItemPrices').map(i => i.hospitemvariantid).includes(f.value))
                                                    }
                                                    value={optionHospItemVariants.find(f => f.value == value) || null}
                                                    onChange={(val) => {
                                                        if (!val) {
                                                            onChange(null)
                                                            setValue(`HospItemPrices.${index}.hospitemvariantcode`, null)
                                                            setValue(`HospItemPrices.${index}.hospitemvariantname`, null)
                                                            return;
                                                        }
                                                        onChange(val.value)
                                                        setValue(`HospItemPrices.${index}.hospitemvariantcode`, val.code)
                                                        setValue(`HospItemPrices.${index}.hospitemvariantname`, val.name)
                                                    }}
                                                />}
                                            />
                                        </td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`HospItemPrices.${index}.unitofmeasure`}
                                                render={({ field: { value, onChange } }) => <InputSelect
                                                    options={optionUoms}
                                                    value={optionUoms.find(f => f.value == value) || null}
                                                    onChange={(e) => {
                                                        if (!e) return onChange(null)
                                                        onChange(e.value)
                                                    }}
                                                    className={clsx({ "form-error": errors.HospItemPrices && errors.HospItemPrices[index]?.unitofmeasure })}
                                                />}
                                            />
                                        </td>
                                        <td>
                                            <Controller
                                                control={control}
                                                name={`HospItemPrices.${index}.unitprice`}
                                                render={({ field: { value, onChange } }) => <InputPrice
                                                    value={value}
                                                    onChange={onChange}
                                                    className={clsx({ "form-error": errors.HospItemPrices && errors.HospItemPrices[index]?.unitprice })}
                                                />}
                                            />
                                        </td>
                                        <td>
                                            <label className="form-check form-check-custom">
                                                <input className="form-check-input" type="checkbox" {...register(`HospItemPrices.${index}.inclvat`)} />
                                            </label>
                                        </td>
                                        <td>
                                            <ExtendClearValue
                                                hide={!watch(`HospItemPrices.${index}.starteddate`)}
                                                handleClear={() => {
                                                    setValue(`HospItemPrices.${index}.starteddate`, null)
                                                }}>
                                                <Controller
                                                    control={control}
                                                    name={`HospItemPrices.${index}.starteddate`}
                                                    render={({ field: { value, onChange } }) => <InputDate
                                                        value={value}
                                                        onChange={(e) => {
                                                            if (!e.length && !(e[0] instanceof Date)) return onChange(null)
                                                            onChange(e[0].toISOString())
                                                        }}
                                                        className={clsx({ 'form-error': errors.HospItemPrices && errors.HospItemPrices[index]?.starteddate })}
                                                    />}
                                                />
                                            </ExtendClearValue>
                                        </td>
                                        <td>
                                            <ExtendClearValue
                                                hide={!watch(`HospItemPrices.${index}.endeddate`)}
                                                handleClear={() => {
                                                    setValue(`HospItemPrices.${index}.endeddate`, null)
                                                }}>
                                                <Controller
                                                    control={control}
                                                    name={`HospItemPrices.${index}.endeddate`}
                                                    render={({ field: { value, onChange } }) => <InputDate
                                                        value={value}
                                                        onChange={(e) => {
                                                            if (!e.length && !(e[0] instanceof Date)) return onChange(null)
                                                            onChange(e[0].toISOString())
                                                        }}
                                                        className={clsx({ 'form-error': errors.HospItemPrices && errors.HospItemPrices[index]?.endeddate })}
                                                    />}
                                                />
                                            </ExtendClearValue>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={clsx("d-flex w-100", { 'd-none': isEdit && !isEditWithPermisstion })}>
                            <span className="btn btn-icon btn-sm btn-light-danger ms-auto d-cell-hover" onClick={() => handleDeleteLine(index, 'HospItemPrices')}>
                                <KTSVG path={'/media/icons/duotune/general/gen027.svg'} className={`svg-icon-4 svg-icon-danger`} />
                            </span>
                        </div>
                    </div>)}
                    <div className={clsx("card card-body border border-secondary", { 'd-none': isEdit && !isEditWithPermisstion })}>
                        <span
                            className={clsx("btn btn-icon btn-sm btn-primary rounded-circle mx-auto")}
                            onClick={() => {
                                const arr = sortWithDate(watch('HospItemPrices').filter(f => f.endeddate), { order: 'desc', sort: 'endeddate' })
                                let newDate = arr.length ? new Date(arr[0].endeddate) : new Date()
                                newDate.setDate(newDate.getDate() + 1)

                                setValue('HospItemPrices', [...(watch('HospItemPrices') || []), {
                                    hospitemid: watch('id') as string,
                                    hospitemcode: watch('code'),
                                    hospitemname: watch('name'),
                                    hospitemvariantid: null,
                                    hospitemvariantcode: null,
                                    hospitemvariantname: null,
                                    unitofmeasure: watch('unitofmeasure'),
                                    unitprice: null,
                                    inclvat: true,
                                    starteddate: newDate.toISOString(),
                                }])
                            }}>
                            <i className="bi bi-plus-lg fs-1"></i>
                        </span>
                    </div>
                </div>
            </div>
        </form>
        <InputDate className="d-none" value={undefined} onChange={(e) => { }} />
        <SearchPopup
            searchData={optionAssignTos}
            keys={cellIndex ? [['code'], ['description']] : []}
            setValue={(key: string, val: any) => {
                if (cellIndex && val && key == 'code') {
                    let temps = watch('HospItemPrices') || []
                    temps[cellIndex.row].customerpricegroupcode = val
                    setValue('HospItemPrices', temps)
                }
                setCellIndex(undefined)
            }}
            handleClose={function (): void {
                setCellIndex(undefined)
            }}
        />
    </>
}